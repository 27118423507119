import './../styles/styles.css';
import data_today from '/data/today_price.json';
import { reload_page, get_zone_color, day_names_us } from './utils.js';
import { table_price } from './table.js';
import { registerSW } from 'virtual:pwa-register'

// Cada 1 hora
const intervalMS = 60 * 60 * 1000

const updateSW = registerSW({
  onNeedRefresh() {
    console.log("ServiceWorker: onNeedRefresh...");
  },
  onOfflineReady() {
    console.log("ServiceWorker: onOfflineReady...");
  },
  onRegisterError(error) {
    console.log("ServiceWorker: onRegisterError...");
  },
  onRegistered(reg) {
    reg && setInterval(() => {
      reg.update()
      console.log("ServiceWorker: Periodic update..")
    }, intervalMS)
    reg.onupdatefound = () => {
      const installingWorker = reg.installing;
      installingWorker.onstatechange = () => {
        if (installingWorker.state === 'activated' &&
            navigator.serviceWorker.controller) {
          // Preferably, display a message asking the user to reload...
          console.log("Refrescando la página");
          location.reload();
        }
      };
    };  
  }
})

let user_hour = new Date().getHours();
let user_minutes = new Date().getMinutes();

let data_prices = data_today.filter(({ day }) => day == get_current_date());

const [{ price }] = data_prices.filter(({ hour }) => +hour == user_hour);

user_hour = user_hour < 10 ? `0${user_hour}` : user_hour;
user_minutes = user_minutes < 10 ? `0${user_minutes}` : user_minutes;

const price_element = document.getElementById('price');

price_element.textContent = `${price.toFixed(4)}`;

const menu_element = document.getElementsByClassName('container-hour-price')[0];

reload_page(user_minutes);

data_prices = data_prices.map(({ hour, price, ...rest }) => {
  return {
    hourHasPassed: +hour < user_hour ? true : false,
    price: price.toFixed(4),
    hour,
    ...rest
  };
});

const [{ zone }] = data_prices.filter(({ hour }) => hour == user_hour);
menu_element.style.backgroundColor = get_zone_color(zone);

function get_current_date() {
  let d = new Date();
  let ye = new Intl.DateTimeFormat('es', { year: 'numeric' }).format(d);
  let mo = new Intl.DateTimeFormat('es', { month: '2-digit' }).format(d);
  let da = new Intl.DateTimeFormat('es', { day: '2-digit' }).format(d);

  return `${da}/${mo}/${ye}`;
}

table_price(data_prices.slice(0, 12), '.container-table-price-left');
table_price(data_prices.slice(12, 24), '.container-table-price-right');

document.getElementById('menu').addEventListener('click', () => {
  window.location = '/precio-luz-manana/';
});